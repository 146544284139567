import React from 'react';
import {ENGAGEMENT_MODELS_DATA} from '../../../Home/engagement_models';
import EngagementModelCard from './EngagementModelCard';

function EngagementModelSection() {
  return (
    <section className="web-dev__engagement-container">
      <div className="web-dev__engagement-heading">Engagement Models</div>
      <p className="web-dev__engagement-description">
        Our extensive experience working with clients ranging from disruptive startups to Fortune
        500 enterprises led us to develop different engagement models tailor-made to your needs.
      </p>
      <div className="web-dev__engagement-cards">
        {ENGAGEMENT_MODELS_DATA.cards.map((card, i) => (
          <EngagementModelCard
            key={i}
            title={card.title}
            benefits={card.benefits}
            suitableFor={card.suitableFor}
          />
        ))}
      </div>
    </section>
  );
}

export default EngagementModelSection;
