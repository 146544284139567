import React from 'react';
import {REVIEWS} from '../Old/ClientReviewsSection';
import ClientReviewCard from '../Old/ClientReviewCard';
// import ClientReviewCard from "./ClientReviewCard"

// I'll use this later.
// const REVIEWS = [
//   {
//     title: "Online Interior Design Company",
//     rating: "5.0",
//     clientIcon: require("../../../../assets/images/services/web-development/client-icon-1.png"),
//     feedbackSummary:
//       "Tintash’s work has been crucial to overall growth, and internal stakeholders are pleased with the design and functionality of the app. Organized and efficient, the team regularly delivers within short timeframes. They’ve displayed an ability to execute while negotiating external challenges.",
//     scores: [
//       {
//         label: "Schedule",
//         rating: "5.0",
//       },
//       {
//         label: "Quality",
//         rating: "5.0",
//       },
//       {
//         label: "Willing to refer",
//         rating: "5.0",
//       },
//       {
//         label: "Cost",
//         rating: "5.0",
//       },
//     ],
//   },
//   {
//     title: "Acciyo",
//     rating: "5.0",
//     clientIcon: require("../../../../assets/images/services/web-development/client-icon-1.png"),
//     feedbackSummary:
//       "The engagement allowed the client to launch a private beta to further test the extension. Tintash keeps the client updated on the progress of the project to ensure effective collaboration. The team is hard-working, creative, and driven.",
//     scores: [
//       {
//         label: "Schedule",
//         rating: "5.0",
//       },
//       {
//         label: "Quality",
//         rating: "5.0",
//       },
//       {
//         label: "Willing to refer",
//         rating: "5.0",
//       },
//       {
//         label: "Cost",
//         rating: "5.0",
//       },
//     ],
//   },
//   {
//     title: "iOS, Android & Web Dev for Real Estate Platform",
//     rating: "5.0",
//     clientIcon: require("../../../../assets/images/services/web-development/client-icon-1.png"),
//     feedbackSummary:
//       "Tintash capably coordinated the development processes of products outside their standard portfolio. They offered valuable opinions regarding the practicality of the existing designs, and the team is receptive to frequent rounds of feedback. Organized and communicative, they are an ideal partner.",
//     scores: [
//       {
//         label: "Schedule",
//         rating: "5.0",
//       },
//       {
//         label: "Quality",
//         rating: "5.0",
//       },
//       {
//         label: "Willing to refer",
//         rating: "5.0",
//       },
//       {
//         label: "Cost",
//         rating: "5.0",
//       },
//     ],
//   },
// ]

function ClientReviewsSection() {
  return (
    <section className="web-dev__client-review-section">
      <div className="web-dev__client-review-container">
        <div className="web-dev__client-review-heading">Verified Client Reviews</div>
        <p className="web-dev__client-review-description">
          Our expertise in web development has been widely appreciated by our clients, whom we
          strive to keep happy and build lasting relationships with by delivering top quality work.
        </p>
        <div className="web-dev__client-review-cards">
          {REVIEWS.map((review, i) => (
            <ClientReviewCard key={i} {...review} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ClientReviewsSection;
