import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import IndustryCard from './IndustryCard';

function IndustrySection() {
  const images = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: {eq: "services/web-development/industry-1.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_2: file(relativePath: {eq: "services/web-development/industry-2.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_3: file(relativePath: {eq: "services/web-development/industry-3.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_4: file(relativePath: {eq: "services/web-development/industry-4.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_5: file(relativePath: {eq: "services/web-development/industry-5.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="web-dev__industry-section">
      <div className="web-dev__industry-container">
        <div className="web-dev__industry-heading">Industry Verticals</div>
        <p className="web-dev__industry-description">
          With over 10 years of experience, our web development teams have had the pleasure of
          partnering with some amazing clients and leading global brands to build and scale their
          products across a wide range of industries.
        </p>
        <div className="web-dev__industry-cards-container">
          <IndustryCard fixed={images.image_1.childImageSharp.fixed} title="Manufacturing" />

          <IndustryCard fixed={images.image_2.childImageSharp.fixed} title="Financial Services" />

          <IndustryCard fixed={images.image_3.childImageSharp.fixed} title="Workplace Management" />

          <IndustryCard fixed={images.image_4.childImageSharp.fixed} title="Lifestyle" />

          <IndustryCard
            fixed={images.image_5.childImageSharp.fixed}
            title="Retail &amp; eCommerce"
          />
        </div>
      </div>
    </section>
  );
}

export default IndustrySection;
