import {Link} from 'gatsby';
import React from 'react';
import StyledBgContainer from './StyledBgContainer';
import TintashJourneyCarousel from './TintashJourneyCarousel';

function OurJourneySection() {
  return (
    <section className="web-dev__our-journey-section">
      <div className="web-dev__our-journey-container">
        <div className="web-dev__our-journey-content-container">
          <div className="web-dev__our-journey-heading">Our Journey So Far</div>
          <p className="web-dev__our-journey-description">
            For over a decade, our web development teams have partnered with growing startups and
            global brands to improve, build and scale their web applications.
          </p>
          <Link
            to="/contact"
            className="web-dev__button web-dev__button--secondary web-dev__our-journey-button"
          >
            Let’s Get Your Project Started
          </Link>
        </div>
        <div className="web-dev__our-journey-carousel-container">
          <StyledBgContainer>
            <TintashJourneyCarousel />
          </StyledBgContainer>
        </div>
      </div>
    </section>
  );
}

export default OurJourneySection;
