import React from 'react';
import Img from 'gatsby-image';

function IndustryCard({fixed, title}) {
  return (
    <div className="web-dev__industry-card">
      <div className="web-dev__industry-card-img">
        <Img
          className="web-dev__industry-card-img"
          fixed={fixed}
          fadeIn={false}
          alt={title}
          loading="lazy"
        />
      </div>
      <div className="web-dev__industry-card-heading">{title}</div>
    </div>
  );
}

export default IndustryCard;
