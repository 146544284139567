import React from 'react';
import {Link} from 'gatsby';

function HeaderSection() {
  return (
    <section className="web-dev__header-section">
      <div className="web-dev__header-container">
        <div className="web-dev__header-inner">
          <h1 className="web-dev__header-title">
            Industry Leading <span className="web-dev__header-title--bold">Web Development</span>
            <span className="sr-only">Company</span>
          </h1>
          <p className="web-dev__header-description">
            Tintash is a leading web development company, specialised in developing custom frontend
            and backend software solutions
          </p>
          <Link
            to="/contact"
            className="web-dev__button web-dev__button--primary web-dev__header-button"
          >
            Build Your Team Today
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeaderSection;
