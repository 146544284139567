import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

function MoreAwardsSection() {
  const images = useStaticQuery(graphql`
    query {
      award_1: file(relativePath: {eq: "services/web-development/award-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 188, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_2: file(relativePath: {eq: "services/web-development/award-2.png"}) {
        childImageSharp {
          fluid(maxWidth: 188, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_3: file(relativePath: {eq: "services/web-development/award-3.png"}) {
        childImageSharp {
          fluid(maxWidth: 188, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_4: file(relativePath: {eq: "services/web-development/award-4.png"}) {
        childImageSharp {
          fluid(maxWidth: 188, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <section className="web-dev__more-awards-section">
      <div className="web-dev__more-awards-container">
        <div className="web-dev__more-award">
          <Img
            className="img-fluid"
            fluid={images.award_1.childImageSharp.fluid}
            alt="AARP's Innovation in Aging Award"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev__more-award">
          <Img
            className="img-fluid"
            fluid={images.award_2.childImageSharp.fluid}
            alt="CABHI Innovation"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev__more-award">
          <Img
            className="img-fluid"
            fluid={images.award_3.childImageSharp.fluid}
            alt="Apple Retail Partner"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev__more-award">
          <Img
            className="img-fluid"
            fluid={images.award_4.childImageSharp.fluid}
            alt="Kids at Play Interactive"
            fadeIn={false}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export default MoreAwardsSection;
