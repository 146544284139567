import React from 'react';

function RightArrow() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.71217 7.69511L1.6777 13.7127C1.29383 14.0956 0.671463 14.0956 0.287784 13.7127C-0.0959263 13.33 -0.0959264 12.7094 0.287784 12.3268L5.62736 7.00217L0.287938 1.67774C-0.0957721 1.29494 -0.0957721 0.674385 0.287938 0.291739C0.671648 -0.0910616 1.29399 -0.0910616 1.67785 0.291739L7.71233 6.30938C7.90418 6.5008 8 6.75141 8 7.00214C8 7.25299 7.904 7.50378 7.71217 7.69511Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default RightArrow;
