import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

export default ({src, alt}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "services/web-development/tech-stack-logos"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fixed(width: 70, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `services/web-development/tech-stack-logos/${src}` === node.relativePath
      ),
    [data, src]
  );

  return (
    <Img
      className="img-fluid"
      imgStyle={{objectFit: 'contain'}}
      placeholderStyle={{objectFit: 'contain'}}
      objectFit="contain"
      objectPosition="50% 50%"
      fixed={match.node.childImageSharp.fixed}
      alt={`Icon - ${alt} Development`}
      fadeIn={false}
      loading="lazy"
    />
  );
};
