import {Link} from 'gatsby';
import React from 'react';

function EngagementModelCard({title, benefits, suitableFor}) {
  return (
    <div className="web-dev__engagement-card">
      <h3 className="web-dev__engagement-card-title">{title}</h3>
      <h4 className="web-dev__engagement-card-sub-heading">Key Benefits</h4>
      <ul className="web-dev__engagement-card-ul">
        {benefits.map(({label}, i) => (
          <li key={i} className="web-dev__engagement-card-li">
            {label}
          </li>
        ))}
      </ul>
      <h4 className="web-dev__engagement-card-sub-heading">Suitable For</h4>
      <ul className="web-dev__engagement-card-ul">
        {suitableFor.map((label, i) => (
          <li key={i} className="web-dev__engagement-card-li">
            {label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EngagementModelCard;
