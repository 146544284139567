import {Link} from 'gatsby';
import React from 'react';
import StyledBgContainer from './StyledBgContainer';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';
import TechStackLogo from './TechStackLogo';

const TECH_STACKS = [
  {
    label: 'Frontend',
    logos: [
      {
        img: 'our-expertise-fe-reactjs.png',
        title: 'React Js',
      },
      {
        img: 'our-expertise-fe-angular.png',
        title: 'Angular Js',
      },
      {
        img: 'our-expertise-fe-vuejs.png',
        title: 'Vue Js',
      },
      {
        img: 'our-expertise-fe-reactivex.png',
        title: 'ReactiveX',
      },
      {
        img: 'our-expertise-fe-jquery.png',
        title: 'jQuery',
      },
    ],
  },
  {
    label: 'Backend',
    logos: [
      {
        img: 'our-expertise-be-django.png',
        title: 'Django',
      },
      {
        img: 'our-expertise-be-ror.png',
        title: 'Ruby on Rails',
      },
      {
        img: 'our-expertise-be-node.png',
        title: 'Node Js',
      },
      {
        img: 'our-expertise-be-java.png',
        title: 'Java',
      },
      {
        img: 'our-expertise-be-golang.png',
        title: 'GoLang',
      },
    ],
  },
  {
    label: 'Cloud DevOps',
    logos: [
      {
        img: 'our-expertise-dev-aws.png',
        title: 'Amazon Web Services',
      },
      {
        img: 'our-expertise-dev-google.png',
        title: 'Google Cloud Platform',
      },
      {
        img: 'our-expertise-dev-firebase.png',
        title: 'Firebase / Cloud Firestorm',
      },
      {
        img: 'our-expertise-dev-heroku.png',
        title: 'Heroku',
      },
      {
        img: 'our-expertise-dev-docker.png',
        title: 'Docker & Kubernetes',
      },
      {
        img: 'our-expertise-dev-jenkins.png',
        title: 'Jenkins',
      },
    ],
  },
];

function ExpertiseSection() {
  const [curItemIndex, setCurItemIndex] = React.useState(0);
  const curItem = TECH_STACKS[curItemIndex];

  const next = () => {
    if (curItemIndex !== TECH_STACKS.length - 1) {
      setCurItemIndex(curItem => ++curItem);
    }
  };

  const prev = () => {
    if (curItemIndex !== 0) {
      setCurItemIndex(curItem => --curItem);
    }
  };

  return (
    <section className="web-dev__expertise-section">
      <div className="web-dev__expertise-container">
        <div className="web-dev__expertise-carousel-container">
          <StyledBgContainer>
            <div className="web-dev__expertise-carousel-card">
              <div className="web-dev__expertise-carousel-header-section">
                <h3 className="web-dev__expertise-carousel-heading">{curItem.label}</h3>
                <div className="web-dev__expertise-carousel-buttons">
                  <button
                    disabled={curItemIndex === 0}
                    className="web-dev__expertise-carousel-button"
                    onClick={prev}
                  >
                    <LeftArrow />
                  </button>
                  <button
                    disabled={curItemIndex === TECH_STACKS.length - 1}
                    className="web-dev__expertise-carousel-button"
                    onClick={next}
                  >
                    <RightArrow />
                  </button>
                </div>
              </div>
              <div className="web-dev__expertise-carousel-grid">
                {curItem.logos.map((logo, i) => (
                  <div key={i} className="web-dev__expertise-carousel-grid-item">
                    <div className="web-dev__expertise-carousel-grid-img">
                      <TechStackLogo src={logo.img} alt={logo.title} />
                    </div>
                    <div className="web-dev__expertise-carousel-grid-title">{logo.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </StyledBgContainer>
        </div>
        <div className="web-dev__expertise-content-container">
          <div className="web-dev__expertise-heading">
            Our Web Application Development Expertise
          </div>
          <p className="web-dev__expertise-description">
            Our team of web developers bring with them over a decade’s worth of experience. From
            working on complex architectures to developing responsive frontend applications, our
            team is skilled in delivering well integrated, leading-edge web solutions for your
            business.
          </p>
          <Link
            to="/contact"
            className="web-dev__button web-dev__button--secondary web-dev__expertise-button"
          >
            Start Your Project
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ExpertiseSection;
