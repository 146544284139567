import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

export default props => {
  const {image: src, alt} = props;

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "services/web-development/client-logos"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `services/web-development/client-logos/${src}` === node.relativePath
      ),
    [data, src]
  );

  return (
    <Img
      className="img-fluid"
      imgStyle={{objectFit: 'contain'}}
      placeholderStyle={{objectFit: 'contain'}}
      objectFit="contain"
      objectPosition="50% 50%"
      fluid={match.node.childImageSharp.fluid}
      alt={alt}
      fadeIn={false}
      loading="eager"
    />
  );
};
