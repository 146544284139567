import React from 'react';
import AwardsAndRecognitionCarousel from '../../../Shared/AwardsAndRecognition/AwardsAndRecognitionCarousel';

function AwardsSection() {
  return (
    <section className="web-dev__awards-section">
      <div className="web-dev__awards-container">
        <div className="web-dev__awards-heading">Awards &amp; Recognitions</div>
        <p className="web-dev__awards-description">
          We’re proud to have picked these recognitions up throughout our journey. To us, they’re
          more than trophies on a shelf, they represent our commitment to bringing the very best to
          our clients and their products.
        </p>

        <div className="web-dev__awards-carousel">
          <AwardsAndRecognitionCarousel
            responsive={{
              lg: {
                breakpoint: {
                  max: 3000,
                  min: 992,
                },
                items: 5,
                partialVisibilityGutter: 20,
                slidesToSlide: 4,
              },
              md: {
                breakpoint: {
                  max: 991,
                  min: 740,
                },
                items: 3,
                partialVisibilityGutter: 20,
                slidesToSlide: 3,
              },
              sm: {
                breakpoint: {
                  max: 740,
                  min: 451,
                },
                items: 2,
                partialVisibilityGutter: 20,
                slidesToSlide: 2,
              },
              xs: {
                breakpoint: {
                  max: 450,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 20,
                slidesToSlide: 1,
              },
            }}
            containerClass="web-dev__awards-carousel-container"
          />
        </div>
      </div>
    </section>
  );
}

export default AwardsSection;
