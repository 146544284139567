import React from 'react';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../../assets/css/services/web-development.css';
import Layout from '../../../components/Shared/Layout';
import withUTMParams from '../../../components/HOC/withUTMParams';
import HeaderSection from '../../../components/Services/WebDevelopment/New/HeaderSection';
import ClientsLogoSection from '../../../components/Services/WebDevelopment/New/ClientsLogoSection';
import AwardsSection from '../../../components/Services/WebDevelopment/New/AwardsSection';
import ExpertiseSection from '../../../components/Services/WebDevelopment/New/ExpertiseSection';
import OurWorkSection from '../../../components/Services/WebDevelopment/New/OurWorkSection';
import WhyTintashSection from '../../../components/Services/WebDevelopment/New/WhyTintashSection';
import OurJourneySection from '../../../components/Services/WebDevelopment/New/OurJourneySection';
import IndustrySection from '../../../components/Services/WebDevelopment/New/IndustrySection';
import EngagementModelSection from '../../../components/Services/WebDevelopment/New/EngagementModelSection';
import ClientReviewsSection from '../../../components/Services/WebDevelopment/New/ClientReviewsSection';
import MoreAwardsSection from '../../../components/Services/WebDevelopment/New/MoreAwardsSection';
import ContactUsSection from '../../../components/Services/WebDevelopment/New/ContactUsSection';

function WebDevelopmentService({location}) {
  React.useEffect(() => {
    ReactGA.set({
      title: 'Services/Web.Development',
    });
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <div>
      <Layout footerProps={{showPortfolioSection: false, showContactSection: false}}>
        <Helmet>
          <title>Industry Leading Web Application Development Company | Tintash</title>
          <meta
            name="description"
            content="Tintash is an Industry Leading Web application development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
          />
        </Helmet>
        <HeaderSection />
        <ClientsLogoSection />
        <AwardsSection />
        <ExpertiseSection />
        <OurWorkSection />
        <MoreAwardsSection />
        <WhyTintashSection />
        <OurJourneySection />
        <IndustrySection />
        <EngagementModelSection />
        <ClientReviewsSection />
        <ContactUsSection />
      </Layout>
    </div>
  );
}

export default withUTMParams(WebDevelopmentService);
