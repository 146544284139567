import React from 'react';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

// const TINTASH_NUMBERS = [
//   {
//     id: 1,
//     number: "$750M",
//     title: "Funding Raised by Clients",
//     description:
//       "We’ve successfully helped some of the most innovative startups develop their initial products, helping them grow to become disruptively innovative companies within their industries.",
//     img: require("../../../../assets/images/services/web-development/tintash-numbers-1.png"),
//   },
//   {
//     id: 2,
//     number: "100+",
//     title: "Projects Delivered to Satisfied Clients",
//     description:
//       "Our expert teams have successfully delivered over 100 projects. Ranging from user centric Mobile and Web Applications, to full service solutions on custom architectures.",
//     img: require("../../../../assets/images/services/web-development/tintash-numbers-2.png"),
//   },
//   {
//     id: 3,
//     number: "150",
//     title: "Strong Team of Experts",
//     description:
//       "We continually grow our team of expert developers in each domain, ensuring that our clients get the very best talent for their needs and industry.",
//     img: require("../../../../assets/images/services/web-development/tintash-numbers-3.png"),
//   },
//   {
//     id: 4,
//     number: "2",
//     title: "Fabled Unicorns as Clients",
//     description:
//       "We’re proud to have been the development partners for some amazing startups, some of which have grown to become successful unicorns.",
//     img: require("../../../../assets/images/services/web-development/tintash-numbers-4.png"),
//   },
//   {
//     id: 5,
//     number: "3",
//     title: "Fortune 500 Clients",
//     description:
//       "Tintash continues to be the development partner of choice for key Fortune 500s, delivering multiple projects to them successfully throughout our partnership.",
//     img: require("../../../../assets/images/services/web-development/tintash-numbers-5.png"),
//   },
// ]

const TINTASH_NUMBERS = [
  {
    id: 1,
    number: '$750M',
    title: 'Funding Raised by Clients',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-1.png')
      .default,
  },
  {
    id: 2,
    number: '300+',
    title: 'Projects Delivered to Satisfied Clients',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-2.png')
      .default,
  },
  {
    id: 3,
    number: '150+',
    title: 'Strong Team of Experts',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-3.png')
      .default,
  },
  {
    id: 4,
    number: '03',
    title: 'Fabled Unicorns as Clients',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-4.png')
      .default,
  },
  {
    id: 5,
    number: '03',
    title: 'Fortune 500 Clients',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-5.png')
      .default,
  },
];

function TintashJourneyCarousel() {
  const [curItemIndex, setCurItemIndex] = React.useState(0);
  const curItem = TINTASH_NUMBERS[curItemIndex];
  const isFirstItem = curItemIndex === 0;
  const isLastItem = curItemIndex === TINTASH_NUMBERS.length - 1;
  const next = () => {
    if (!isLastItem) {
      setCurItemIndex(curItem => ++curItem);
    }
  };

  const prev = () => {
    if (!isFirstItem) {
      setCurItemIndex(curItem => --curItem);
    }
  };
  return (
    <div className="web-dev__our-journey-carousel-card">
      <img className="web-dev__our-journey-carousel-img" src={curItem.img} alt={curItem.title} />
      <p className="web-dev__our-journey-carousel-title web-dev__our-journey-carousel-title--light">
        {curItem.title}
      </p>
      <div className="web-dev__our-journey-carousel-title">{curItem.number}</div>

      <div className="web-dev__our-journey-carousel-buttons">
        <button
          disabled={isFirstItem}
          className="web-dev__our-journey-carousel-button"
          onClick={prev}
        >
          <LeftArrow />
        </button>
        <button
          disabled={isLastItem}
          className="web-dev__our-journey-carousel-button"
          onClick={next}
        >
          <RightArrow />
        </button>
      </div>
    </div>
  );
}

export default TintashJourneyCarousel;
