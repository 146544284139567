import React from 'react';

function LeftArrow() {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.287828 6.30953L6.3223 0.291979C6.70617 -0.0910081 7.32854 -0.0910081 7.71222 0.291979C8.09593 0.674624 8.09593 1.29524 7.71222 1.67786L2.37264 7.00247L7.71206 12.3269C8.09577 12.7097 8.09577 13.3303 7.71206 13.7129C7.32835 14.0957 6.70601 14.0957 6.32215 13.7129L0.287673 7.69525C0.0958184 7.50384 -5.89821e-07 7.25323 -6.11741e-07 7.0025C-6.33671e-07 6.75165 0.0960043 6.50086 0.287828 6.30953Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LeftArrow;
