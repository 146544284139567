import {Link} from 'gatsby';
import React from 'react';

function ContactUsSection() {
  return (
    <div className="web-dev__contact__section">
      <div className="web-dev__contact-container">
        <p className="web-dev__contact-title">We have worked with some great clients!</p>
        <Link
          to="/contact"
          className="web-dev__button web-dev__button--big web-dev__button--secondary-invert web-dev__contact-link"
        >
          Start Your Project
        </Link>
      </div>
    </div>
  );
}

export default ContactUsSection;
