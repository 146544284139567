import {Link,useStaticQuery, graphql} from 'gatsby';
import React from 'react';

import Img from 'gatsby-image';
import StyledBgContainer from './StyledBgContainer';

function WhyTintashSection() {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: {eq: "services/web-development/why-us-cover.png"}) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <section className="web-dev__why-tin-section">
      <div className="web-dev__why-tin-container">
        <div className="web-dev__why-tin-content-container">
          <div className="web-dev__why-tin-heading">Why Tintash</div>
          <ul className="web-dev__why-tin-checklist">
            <li className="web-dev__why-tin-checklist-item">Carefully curated Agile Teams</li>
            <li className="web-dev__why-tin-checklist-item">Remote network of Developers</li>
            <li className="web-dev__why-tin-checklist-item">Time Zones Aligned with your Team</li>
            <li className="web-dev__why-tin-checklist-item">
              Proven Track Record with 10+ Years In the Industry
            </li>
          </ul>
          <Link
            to="/contact"
            className="web-dev__button web-dev__button--secondary web-dev__why-tin-button"
          >
            Let’s Get Your Project Started
          </Link>
        </div>
        <div className="web-dev__why-tin-artwork">
          <StyledBgContainer>
            <Img
              imgStyle={{objectFit: 'contain'}}
              placeholderStyle={{objectFit: 'contain'}}
              objectFit="contain"
              objectPosition="50% 50%"
              fluid={data.cover.childImageSharp.fluid}
              alt="Cover"
              fadeIn={false}
              loading="lazy"
            />
          </StyledBgContainer>
        </div>
      </div>
    </section>
  );
}

export default WhyTintashSection;
