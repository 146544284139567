import React from 'react';

function StyledBgContainer({children}) {
  return (
    <div className="web-dev__bg-overlay">
      <div className="web-dev__bg-overlay-1" />
      <div className="web-dev__bg-overlay-2" />
      <div className="web-dev__bg-overlay-3" />
      <div className="web-dev__bg-overlay-content">{children}</div>
    </div>
  );
}

export default StyledBgContainer;
